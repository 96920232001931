export default [
  {
    key: "report",
    name: "报表统计",
    icon: "line-chart",
    submenus: [
      { key: "/report/sale_report", name: "销售报表", type: "sales" },
      { key: "/report/purchase_report", name: "采购报表", type: "purchase" },
      { key: "/report/batch_report", name: "库存明细" },
      { key: "/report/stock_report", name: "库存报表" },
      { key: "/report/income_expense_statistics", name: "收支统计", type: "finance" },
      { key: "/production/record", name: "生产报表", type: "production" },
    ],
  },
  {
    key: "approval",
    name: "工作流",
    icon: "apartment",
    submenus: [
      { key: "/approval/approval_task", name: "待审批任务" },
      { key: "/approval/approval_order", name: "我的申请" },
      { key: "/approval/approval_record", name: "历史审批任务" },
    ],
  },
  {
    key: "data",
    name: "基础数据",
    icon: "table",
    submenus: [
      { key: "/basicData/client", name: "客户管理" },
      { key: "/basicData/supplier", name: "供应商管理" },
      { key: "/basicData/warehouse", name: "仓库管理" },
      { key: "/production/workshop", name: "车间管理", type: "production" },
      { key: "/production/workstation", name: "工作站管理", type: "production" },
    ],
  },
  {
    key: "product",
    name: "产品管理",
    icon: "appstore",
    submenus: [
      { key: "/goods/classification", name: "产品分类" },
      { key: "/goods/unit", name: "产品单位" },
      { key: "/goods/information", name: "产品信息" },
      { key: "/goods/bom_detail", name: "BOM管理", type: "production" },
      { key: "/goods/temporary_warning", name: "临期预警" },
    ],
  },
  {
    key: "purchase",
    name: "采购管理",
    icon: "shopping-cart",
    submenus: [
      { key: "/purchasing/purchase_record", name: "采购单", type: "purchase" },
      { key: "/purchasing/return_record", name: "采购退货", type: "purchase" },
    ],
  },
  {
    key: "sales",
    name: "销售管理",
    icon: "shopping",
    submenus: [
      { key: "/sale/sale_record", name: "销售单", type: "sales" },
      { key: "/sale/sale_return_record", name: "销售退货", type: "sales" },
    ],
  },
  {
    key: "process",
    name: "工艺管理",
    icon: "branches",
    submenus: [
      { key: "/production/process", name: "工序管理", type: "production" },
      { key: "/production/production_route", name: "工艺路线", type: "production" },
    ],
  },
  {
    key: "production",
    name: "生产管理",
    icon: "schedule",
    submenus: [
      { key: "/production/plan", name: "生产工单", type: "production" },
      { key: "/production/scheduling", name: "工单排产", type: "production" },
      { key: "/production/task", name: "生产任务", type: "production" },
    ],
  },
  {
    key: "inventory",
    name: "库存管理",
    icon: "database",
    submenus: [
      { key: "/warehouse/inStock", name: "入库任务" },
      { key: "/warehouse/outStock", name: "出库任务" },
      { key: "/warehouse/flow", name: "库存流水" },
    ],
  },
  {
    key: "equipment",
    name: "设备管理",
    icon: "control",
    submenus: [
      { key: "/equipment/equipment_category", name: "设备类型", type: "equipment" },
      { key: "/equipment/equipment", name: "设备台账", type: "equipment" },
      { key: "/equipment/maintenance_item", name: "点检保养项目", type: "equipment" },
      { key: "/equipment/maintenance_plan", name: "点检保养计划", type: "equipment" },
      { key: "/equipment/maintenance_task", name: "点检保养任务", type: "equipment" },
      // { key: "/equipment/equipment_ledger", name: "设备台账", type: "equipment" },
      // { key: "/equipment/inspection_project", name: "点检保养项目", type: "equipment" },
      // { key: "/equipment/inspection_plan", name: "点检保养计划", type: "equipment" },
      // { key: "/equipment/inspection_task", name: "点检保养任务", type: "equipment" },
      // { key: "/equipment/repair_order", name: "维修单", type: "equipment" },
      // { key: "/equipment/repair_task", name: "维修任务", type: "equipment" },
    ],
  },
  {
    key: "inspection",
    name: "质量管理",
    icon: "audit",
    submenus: [
      // { key: "/inspection/common_defect", name: "常见缺陷", type: "inspection" },
      // { key: "/inspection/inspection_point", name: "检测项", type: "inspection" },
      // { key: "/inspection/inspection_template", name: "检测模板", type: "inspection" },
      // { key: "/inspection/pending_inspection_task", name: "待检任务", type: "inspection" },
      // { key: "/inspection/incoming_inspection_list", name: "来料检验", type: "inspection" },
      // { key: "/inspection/process_inspection_list", name: "过程检验", type: "inspection" },
      // { key: "/inspection/delivery_inspection_list", name: "发货检验", type: "inspection" },
      { key: "/inspection/purchase_inspection_report_list", name: "来料检验报告", type: "inspection" },
      { key: "/inspection/production_inspection_report_list", name: "成品检验报告", type: "inspection" },
    ],
  },
  {
    key: "finance",
    name: "财务管理",
    icon: "dollar",
    submenus: [
      { key: "/basicData/settlement_account", name: "结算账户", type: "finance" },
      { key: "/basicData/revenue_expenditure_items", name: "收支项目", type: "finance" },
      { key: "/finance/arrears_payable", name: "应付账款", type: "finance" },
      { key: "/finance/arrears_receivable", name: "应收账款", type: "finance" },
      { key: "/finance/account_transfer", name: "账户转账", type: "finance" },
      { key: "/finance/income_and_pay", name: "日常收支", type: "finance" },
      { key: "/finance/flow", name: "资金流水", type: "finance" },
    ],
  },
  {
    key: "system",
    name: "系统管理",
    icon: "team",
    submenus: [
      { key: "/role", name: "角色管理" },
      { key: "/account", name: "员工账号" },
      { key: "/config", name: "系统配置" },
      { key: "/logo_configs", name: "Logo配置" },
      { key: "/operation_log", name: "操作日志" },
      { key: "/login_log", name: "登录日志" },
      { key: "/update_log", name: "更新日志" },
      { key: "/mobile_download", name: "移动端下载" },
    ],
  },
];
