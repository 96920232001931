<template>
  <div style="height: 100vh">
    <div class="logo" @click="$router.push('/')" style="width: 256px">
      <img
        :src="logoItem.logo_image ? logoItem.logo_image_item.file : logo"
        width="36"
        style="margin-top: -6px; margin-left: 8px"
      />
      <span v-if="!collapsed" style="color: #1890ff; margin-left: 12px; font-size: 16px">
        {{ logoItem.logo_title && logoItem.logo_title.length > 0 ? logoItem.logo_title : "盒木" }}MES
      </span>
      <span v-if="!collapsed" style="color: #1890ff; margin-left: 6px">
        <span style="font-size: 12px">V2.0</span>
      </span>
    </div>
    <a-menu
      theme="light"
      mode="inline"
      :selectedKeys="selectedKeys"
      :openKeys="openKeys"
      :inline-collapsed="collapsed"
      :style="{ width: collapsed ? '80px' : '256px' }"
      @click="switchView"
      @openChange="openChange"
    >
      <a-menu-item key="/home"> <a-icon type="home" /><span>首页</span> </a-menu-item>

      <a-sub-menu v-for="menu in curMenus" :key="menu.key">
        <span slot="title">
          <a-icon :type="menu.icon" /><span>{{ menu.name }}</span></span
        >
        <a-menu-item v-for="submenu of menu.submenus" :key="submenu.key">{{ submenu.name }}</a-menu-item>
      </a-sub-menu>
    </a-menu>

    <!-- <div style="position: fixed; bottom: 0;">
      <a-space :size="32">
        <div style="float: left; padding-left: 32px;">
          <img :src="wechatCustomerService" width="80" style="margin-top: -6px;" />
          <div style="text-align: center; color: #777; font-size: 14px; margin-top: 4px;">微信客服</div>
        </div>
        <div style="float: left;">
          <img :src="wechatExchange" width="80" style="margin-top: -6px;" />
          <div style="text-align: center; color: #777; font-size: 14px; margin-top: 4px;">微信交流群</div>
        </div>
      </a-space>
    </div> -->
  </div>
</template>

<script>
import menus from "@/menus.js";

export default {
  name: "Sidebar",
  props: ["collapsed"],
  data() {
    return {
      menus,
      openKeys: [],
      selectedKeys: [],
      logo: require("@/assets/logo.png"),
      // wechatCustomerService: require('@/assets/wechat_customer_service.jpg'),
      // wechatExchange: require('@/assets/wechat_exchange.png'),
    };
  },
  computed: {
    logoItem() {
      return this.$store.state.system.logoItem;
    },
    enablePurchaseManage() {
      return this.$store.state.user.enablePurchaseManage;
    },
    enableSalesManage() {
      return this.$store.state.user.enableSalesManage;
    },
    enableProductionManage() {
      return this.$store.state.user.enableProductionManage;
    },
    enableFinanceManage() {
      return this.$store.state.user.enableFinanceManage;
    },
    enableEquipmentManage() {
      return this.$store.state.user.enableEquipmentManage;
    },
    enableInspectionManage() {
      return this.$store.state.user.enableInspectionManage;
    },
    curMenus() {
      const menuItems = [];
      for (const menu of menus) {
        const menuItem = { ...menu };
        const submenuItems = [];
        for (const submenuItem of menuItem.submenus) {
          if (!this.enablePurchaseManage && submenuItem.type === "purchase") {
            continue;
          }

          if (!this.enableSalesManage && submenuItem.type === "sales") {
            continue;
          }

          if (!this.enableProductionManage && submenuItem.type === "production") {
            continue;
          }

          if (!this.enableFinanceManage && submenuItem.type === "finance") {
            continue;
          }

          if (!this.enableEquipmentManage && submenuItem.type === "equipment") {
            continue;
          }

          if (!this.enableInspectionManage && submenuItem.type === "inspection") {
            continue;
          }

          submenuItems.push(submenuItem);
        }

        if (submenuItems.length === 0) {
          continue;
        }

        menuItem.submenus = submenuItems;
        menuItems.push(menuItem);
      }

      return menuItems;
    },
  },
  methods: {
    initialize() {
      this.changeRoute(this.$route.path);
    },
    switchView(item) {
      if (this.$route.path !== item.key) {
        this.$router.push(item.key);
      }
    },
    openChange(openKeys) {
      this.openKeys = [openKeys[openKeys.length - 1]];
    },
    changeRoute(path) {
      this.selectedKeys = [path];
      for (let m of menus) {
        if (m.submenus.findIndex((item) => item.key == path) != -1) {
          this.openKeys = [m.key];
          return;
        }
      }
    },
  },
  watch: {
    $route(to) {
      this.changeRoute(to.path);
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style scoped>
.logo {
  font-weight: bold;
  font-size: 20px;
  height: 64px;
  padding: 16px;
  border-bottom: 1px solid #6662;
  cursor: pointer;
}
.ant-menu-inline {
  border-right: 0px !important;
}
</style>
